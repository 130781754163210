/* Pagination & pager */

.pager li > a, 
.pager li > span,
.pagination > li > a {
    border-color: #e2e7eb;
    color: @dark_black;
}
.pager.pager-without-border li > a, 
.pager.pager-without-border li > span,
.pagination.pagination-without-border > li > a {
    border-color: @white;
}
.pagination > .disabled > span, 
.pagination > .disabled > span:hover, 
.pagination > .disabled > span:focus, 
.pagination > .disabled > a, 
.pagination > .disabled > a:hover, 
.pagination > .disabled > a:focus,
.pager > .disabled > span,
.pager > .disabled > a {
    .opacity(0.6);
    border-color: #ddd;
}
.pagination > li > a {
    color: @dark_black;
    margin-left: 5px;
    .border-radius(3px) !important;
}
.pagination > li:first-child > a {
    margin-left: 0;
}
.pagination-sm > li > a, 
.pagination-sm > li > span {
    font-size: 10px;
    margin-left: 4px;
}
.pagination-lg > li > a, 
.pagination-lg > li > span {
    font-size: 14px;
    margin-left: 6px;
}
.pager li > a:hover, 
.pager li > a:focus, 
.pager li > span:hover,
.pager li > span:focus,
.pagination > li > a:hover,
.pagination > li > a:focus {
    color: @dark_black;
    background: #e2e7eb;
    border-color: #d8dde1;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
    background: @dark_black !important;
    border-color: @dark_black !important;
}