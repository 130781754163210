/* Responsive Setting */

@media(max-width: 979px) {
    .form-horizontal.form-bordered .form-group {
        border-bottom: 1px solid #eee;
        
        & .control-label {
            padding: 15px;
            line-height: 34px;
        }
        & > div {
            padding: 15px;
        }
        &, 
        & > div,
        & > .control-label {
            border: none;
        }
    }
}

@media (max-width: 767px) {
    .table-responsive .table > thead > tr > th, 
    .table-responsive .table > tbody > tr > th, 
    .table-responsive .table > tfoot > tr > th, 
    .table-responsive .table > thead > tr > td, 
    .table-responsive .table > tbody > tr > td, 
    .table-responsive .table > tfoot > tr > td {
        white-space: nowrap;
    }
    .form-horizontal.form-bordered .form-group > .control-label {
        padding: 15px 15px 0;
        line-height: inherit;
    }
    .form-horizontal.form-bordered .form-group > div {
        padding: 5px 15px 15px;
    }
    .theme-panel {
        top: 0;
        bottom: 0;
    }
    .theme-panel .theme-collapse-btn {
        top: 150px;
    }
    .theme-panel .theme-panel-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        overflow: scroll;
    }
}