/* Button */

.btn {
    font-weight: 300;
    .border-radius(3px);
    
    &:focus,
    &:active:focus,
    &.active:focus {
        outline: none;
    }
    &:active,
    &.active {
        .box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.1));
    } 
}
.btn-icon,
.btn.btn-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    
    &.btn-xs {
        width: 16px;
        height: 16px;
        font-size: 8px;
        line-height: 16px;
    }
    &.btn-sm {
        width: 22px;
        height: 22px;
        font-size: 11px;
        line-height: 22px;
    }
    &.btn-lg {
        width: 34px;
        height: 34px;
        font-size: 17px;
        line-height: 34px;
    }
    & > .pull-left,
    & > .pull-right {
        line-height: 1.428571429;
    }
}

.btn-circle,
.btn.btn-circle {
    .border-radius(50%);
}
.btn-scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 25px;
    z-index: 1020;
}
.btn-block {
    padding-left: 12px;
    padding-right: 12px;
}


/* 
    Button Generator (inside _mixins.less) 
    ----------------
    .generate-button-styling(@buttonClassName; @defaultColor; @hoverColor;); 
*/

// .btn-default
.generate-button-styling(btn-default; @grey; @dark_grey);

// .btn-inverse
.generate-button-styling(btn-inverse; @black; @dark_black);

// .btn-primary
.generate-button-styling(btn-primary; @blue; @dark_blue);

// .btn-success
.generate-button-styling(btn-success; @green; @dark_green);

// .btn-warning
.generate-button-styling(btn-warning; @orange; @dark_orange);

// .btn-danger
.generate-button-styling(btn-danger; @red; @dark_red);

// .btn-info
.generate-button-styling(btn-info; @aqua; @dark_aqua);

// .btn-white
.btn.btn-white {
    font-weight: normal;
    color: #333;
    background: @white;
    border-color: #e2e7eb;
    
    &:hover, 
    &:focus, 
    &:active, 
    &.active {
        background: #e2e7eb;
        border-color: #d8dde1;
    }
    
    &.btn-white-without-border {
        border-color: @white;
        
        &.active,
        &.active:hover,
        &.active:focus {
            border-color: #ddd;
        }
        &:hover,
        &focus {
            border-color: #eee;
        }
    }
}
.open .dropdown-toggle.btn-white {
    background: #e2e7eb;
    border-color: #d8dde1;
}
.btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
    border-left-color: #eee;
}


