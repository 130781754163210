/* Form Elements */

.form-control {
    border: 1px solid #ccd0d4;
    font-size: 12px;
    .border-radius(3px);
    .box-shadow(none);
}
.form-control.input-white {
    background: #fff;
    border-color: #fff;
}
.form-control.input-white:focus {
    .box-shadow(none);
}
.form-control[disabled], 
.form-control[readonly],
fieldset[disabled] .form-control {
    background: #e5e9ed;
    .opacity(0.6);
}
.form-control[disabled]:focus, 
.form-control[readonly]:focus,
fieldset[disabled] .form-control:focus {
    .box-shadow(none);
    border: 1px solid #ccd0d4;
}
.form-control:focus {
    border-color: #9fa2a5;
    .box-shadow(none);
}
.form-horizontal.form-bordered .form-group {
    border-bottom: 1px solid #eee;
    margin: 0;
}
.form-horizontal.form-bordered .form-group:last-child {
    border-bottom: 0;
}
.form-horizontal.form-bordered .form-group > .control-label {
    padding: 22px 15px 15px;
}
.form-horizontal.form-bordered .form-group > div {
    padding: 15px;
}
.form-horizontal.form-bordered .form-group > div {
    border-left: 1px solid #eee;
}
.form-horizontal.form-bordered .form-group > .control-label {
    border-right: 1px solid #eee;
    margin-right: -1px;
}
.form-horizontal.form-bordered .has-feedback .form-control-feedback {
    top: 15px;
}
select.form-control {
    border-color: #ccd0d4;
}
select[multiple].form-control {
    border-color: #ccd0d4;
}
label {
    font-weight: 500;
}
.input-group-addon {
    background: #e2e7eb;
    border: none;
}
legend {
    padding-bottom: 3px;
    border-bottom: 1px solid #e2e7eb;
}

#cancel-search-btn {
	margin-top: 8px;
	position: absolute;
	right: 75px;
}
.per-page-dropdown,
.search-form-input {
    border: 1px solid #ccd0d4;
    background: #fff;
    font-size: 12px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.per-page-dropdown {
    margin-right: 10px;
    height: 34px !important;
    width: auto !important;
}
.search-form-input {
    border-color: #ccd0d4;
    height: 34px;
    margin-left: 10px;
}
.search-form-input:focus {
    outline: 0;
    border-color: #9fa2a5;
    -webkit-box-shadow: none;
    box-shadow: none;
}